import React, { useState, useEffect, useRef, useCallback } from "react";
import { CircularProgress } from "@mui/material";
import { useRecoilState } from "recoil";
import { Editor } from "@tinymce/tinymce-react";
import { useRecoilValue } from "recoil";
import { speedDialState, uploadingState } from "../Global/GlobalStates";

export default function TinyMCE({
  text,
  gptTextResponse,
  audioTranscript,
  isFetching,
}) {
  const [tinyMCEApiKey, setTinyMCEApiKey] = useState("");
  const editorRef = useRef(null);
  const uploading = useRecoilValue(uploadingState);
  const [conditionalText, setConditionalText] = useState(
    "To begin recording, click the 'Start Recording' button in the top left corner of the screen. Once you end a recording, your consultation notes will be generated here! 😁"
  );

  const [selectedSpeedDialOption, setSelectedSpeedDialOption] =
    useRecoilState(speedDialState);

  const serverUrl = "https://server.calvin-ai.com";

  // console.log(selectedSpeedDialOption);

  const resetSpeedDialOption = useCallback(() => {
    setSelectedSpeedDialOption(""); // Reset to a default value
  }, [setSelectedSpeedDialOption]); // Dependencies array

  useEffect(() => {
    if (selectedSpeedDialOption === "Clear") {
      setConditionalText("");
      editorRef.current.execCommand("mceSetContent", false, "");
      resetSpeedDialOption();
    } else if (text !== "") {
      setConditionalText(text);
    }
  }, [selectedSpeedDialOption, resetSpeedDialOption, text]); // Dependency array to prevent unnecessary re-renders

  useEffect(() => {
    // console.log("Updated Text from TinyMCE:", gptTextResponse);
    // console.log("Updated Transcript from TinyMCE:", audioTranscript);
  }, [gptTextResponse, audioTranscript]);

  useEffect(() => {
    fetch(`${serverUrl}/tinyMCE-config`) // Adjust URL as needed
      .then((response) => response.json())
      .then((data) => {
        setTinyMCEApiKey(data.tinyMCEConfig.www);
      })
      .catch((error) => {
        console.error("Error fetching API keys:", error);
      });
  }, []);

  if (!tinyMCEApiKey) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Editor
      disabled={uploading}
      onInit={(evt, editor) => {
        editorRef.current = editor; // Store the editor instance
      }}
      apiKey={tinyMCEApiKey}
      init={{
        content_style: `.tox-tinymce { border-radius: 0px; }`, // Adjust the border-radius as needed
        height: "100%",
        width: "100%",
        statusbar: false,
        plugins:
          "tinycomments mentions anchor autolink charmap codesample emoticons image lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents inlinecss",
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
        tinycomments_mode: "embedded",
        tinycomments_author: "Author name",
      }}
      initialValue={conditionalText}
    />
  );
}
