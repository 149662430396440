import "./FileUploader.css";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Button } from "@mui/material";
import { useRef, useState, useCallback, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { LoadingButton } from "@mui/lab";
import {
  displayedTextState,
  uploadingState,
  notificationState,
  documentIdState,
  useAiAssistantClickedState,
} from "../../Global/GlobalStates";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "axios";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    minHeight: "auto", // Auto height to grow with content
    maxHeight: "50vh", // Maximum height
    overflowY: "auto", // Scroll vertically if content exceeds maxHeight
    resize: "none", // Disable manual resizing
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function FileUploader({ onUploadComplete }) {
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useRecoilState(uploadingState);
  const [documentId, setDocumentId] = useRecoilState(documentIdState);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const setDisplayedText = useSetRecoilState(displayedTextState);
  const setNotification = useSetRecoilState(notificationState);
  const [query, setQuery] = useState("");
  const [isVisible, setIsVisible] = useState(false); // State to manage visibility
  const [aiAssistantClicked] = useAiAssistantClickedState();
  const [isDragOver, setIsDragOver] = useState(false); // New state for drag over
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
    }
  }, []);

  let className = "file-uploader";
  if (hasMounted.current && isVisible) {
    className += aiAssistantClicked ? " slide-up" : " slide-down";
  }

  const isSupportedFileType = (file) => {
    const allowedTypes = [
      "text/html",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    return allowedTypes.includes(file.type);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // This is crucial for allowing the drag event to continue
    if (!isDragOver) {
      setIsDragOver(true); // Set the state if not already done
    }
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  useEffect(() => {
    if (aiAssistantClicked) {
      setIsVisible(true);
    } else {
      const timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 500); // 500ms matches your animation duration
      return () => clearTimeout(timeoutId);
    }
  }, [aiAssistantClicked]);

  const handleFileUpload = useCallback(async () => {
    const formData = new FormData();
    if (fileInputRef.current && fileInputRef.current.files) {
      Array.from(fileInputRef.current.files).forEach((file) => {
        formData.append("files", file);
      });

      try {
        setUploading(true);
        const response = await axios.post(
          "https://droplet.calvin-ai.com/process_files/",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        setDocumentId(response.data.id);
      } catch (error) {
        console.error("Error uploading files:", error);
        setUploadedFiles([]);
        setNotification({
          isVisible: true,
          message: `Error processing files: ${error.message}. Please try again.`,
        });
        // Clear the file input on error
        fileInputRef.current.value = "";
      }
      setUploading(false);
    }
  }, [setDocumentId, setNotification, setUploading]);

  const handleFileSelect = async (event) => {
    setUploadedFiles([]);
    const files = event.target.files;
    if (files.length > 0) {
      setUploadedFiles(Array.from(files).map((file) => file.name));
      setUploading(true);
      await handleFileUpload();
      console.log("Files Uploaded:", files);
    } else {
      // Handle case where user cancels the file dialog
      setUploadedFiles([]);
    }
  };

  const handleDrop = useCallback(
    async (event) => {
      event.preventDefault();
      setIsDragOver(false); // Reset drag over state
      setUploadedFiles([]); // Clear existing files before setting new ones
      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        if (isSupportedFileType(files[0])) {
          setUploadedFiles(Array.from(files).map((file) => file.name));
          await handleFileUpload();
          console.log("Files Uploaded:", files);
        } else {
          setNotification({
            isVisible: true,
            message:
              "Unsupported file type. Please upload HTML, DOCX, or PDF files.",
          });
        }
      }
    },
    [handleFileUpload, setNotification]
  );

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleSendClick = async () => {
    try {
      setUploading(true);
      const response = await axios.post(
        "https://droplet.calvin-ai.com/fetch_response/",
        {
          query: query,
          doc_id: documentId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      onUploadComplete();
      setDisplayedText(response.data.response);
    } catch (error) {
      console.error("Error fetching response:", error);
      setNotification({
        isVisible: true,
        message: `Error fetching response: ${error.message}. Please try again.`,
      });
    }
    setUploading(false);
  };

  return isVisible ? (
    <div className={className}>
      <div className="text-area-container">
        <p className="text-area" style={{ color: "#0067FF" }}>
          Upload your files (PDF, DOCX or HTML format), and enter your
          instruction below. After clicking the "Generate" button, a document
          will be displayed in the text editor above.
        </p>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelect}
        multiple
        style={{ display: "none" }}
        accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html"
      />
      <Button
        disabled={uploading}
        className={`top-upload-container ${isDragOver ? "hover-state" : ""}`}
        sx={{ textTransform: "none" }}
        onClick={triggerFileInput}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="upload-field" style={{ position: "relative" }}>
          <div className="file-names-container">
            {!uploading && uploadedFiles.length > 0 ? (
              <p id="upload-field-text">
                Number of Documents Uploaded: {uploadedFiles.length}
              </p>
            ) : uploading ? (
              <p id="upload-field-text">
                <LoadingButton
                  loading
                  sx={{
                    border: "none !important",
                  }}
                  variant="outlined"
                />
              </p>
            ) : (
              <div id="upload-field-text">
                <CloudUploadOutlinedIcon
                  sx={{
                    fontSize: "55px",
                    marginTop: "15px",
                    color: "#dfdfdf",
                  }}
                />
                <p style={{ marginTop: "0px" }}>
                  Drag and Drop or Click to Browse
                </p>
              </div>
            )}
          </div>
        </div>
      </Button>
      <div className="bottom-upload-container">
        <div className="input-and-send">
          <FormControl variant="standard">
            <BootstrapInput
              disabled={uploading}
              placeholder="Type your instruction here..."
              id="bootstrap-input"
              multiline
              minRows={1} // Adjust the minimum number of rows
              maxRows={14} // Adjust the maximum number of rows before scrolling
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </FormControl>

          <Button
            disabled={
              uploading || query.trim() === "" || uploadedFiles.length === 0
            }
            variant="outlined"
            sx={{ marginLeft: "10px", marginBottom: "4.5px" }}
            onClick={handleSendClick}
            disableElevation
          >
            <AutoAwesomeIcon sx={{ padding: "4.5px" }} />{" "}
            <p
              style={{
                margin: "0px",
                padding: "4.5px",
                paddingLeft: "0px",
                fontWeight: "bold",
              }}
            >
              Generate
            </p>
          </Button>
        </div>
      </div>
    </div>
  ) : null;
}
