import "./TitlePage.css";
import * as React from "react";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { isPayingCustomer } from "../../../../utils/isPayingCustomer";
import createStripeCheckoutSession from "../../../../utils/createStripeCheckoutSession";

export default function TitlePage() {
  const { logout, user } = useAuth0();
  const [isPayingCustomerStatus, setIsPayingCustomer] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState("");

  useEffect(() => {
    console.log(
      "isPayingCustomerStatus:",
      isPayingCustomerStatus,
      "Logout:",
      logout
    );
    const fetchPayingCustomerStatus = async () => {
      const status = await isPayingCustomer({ email: user.email });
      setIsPayingCustomer(status);
      const stripeCheckoutUrl = await createStripeCheckoutSession({
        email: user.email,
      });
      setCheckoutUrl(stripeCheckoutUrl);
    };

    if (user && user.email) {
      fetchPayingCustomerStatus();
    }
  }, [user, isPayingCustomerStatus, logout]);

  const openStripeLink = () => {
    window.open(checkoutUrl);
  };
  return (
    <div className="title-page">
      <h1 className="title">Continue The Best Clinical Experience Ever.</h1>
      <p className="sub-title">
        Subscribe now to keep automating the clinical note taking process with
        an intuitive platform featuring AI-powered transcription and note
        generation.
      </p>
      <a
        href="https://dev-v7a5ioospfgu6dg4.us.auth0.com/u/signup?state=hKFo2SBFTGpiV1lhdEhHVFpEakhRbVFwdXlCTm9lSWZjYzRVc6Fur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFhVMEg0VnRCX09CaGhpRzdKMUhrUWg5MjdXR3luR3BKo2NpZNkgYW5hTEE3RERzam5NZHJaZ1gxa3Y0ckJmVk9iaENlNEk"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          variant="contained"
          disableElevation
          onClick={openStripeLink}
          sx={{ background: "#0067ff", padding: "8px 18px" }}
        >
          Subscribe to Calvin AI
        </Button>
      </a>
    </div>
  );
}
