// utilities.js

export const formatTime = (millis) => {
  let minutes = Math.floor(millis / 60000)
    .toString()
    .padStart(2, "0");
  let seconds = ((millis % 60000) / 1000).toFixed(0).padStart(2, "0");
  let milliseconds = Math.floor((millis % 1000) / 10)
    .toString()
    .padStart(2, "0");
  return `${minutes}:${seconds}.${milliseconds}`;
};

export const generateFilename = (mimeType) => {
  const extension = mimeType.split("/")[1].split(";")[0]; // Extracts extension from MIME type
  const timestamp = new Date().toISOString().replace(/[:.]/g, "-"); // Timestamp for uniqueness
  return `recording_${timestamp}.${extension}`;
};
