import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

export default function LoadingButton1() {
  return (
    <LoadingButton loading loadingPosition="center" variant="outlined">
      Generating Notes
    </LoadingButton>
  );
}
