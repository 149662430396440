const serverUrl = "https://server.calvin-ai.com";
export const isPayingCustomer = async (email) => {
  try {
    const response = await fetch(`${serverUrl}/verify-customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    return result.isPaying;
  } catch (error) {
    console.error("Error while checking customer status:", error);
    return false; // Or handle the error as per your application's needs
  }
};
