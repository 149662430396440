import { atom, useRecoilState } from "recoil";

export const savedNotesClickedState = atom({
  key: "savedNotesClickedState",
  default: false,
});

export function useSavedNotesClicked() {
  return useRecoilState(savedNotesClickedState);
}

export const aiAssistantClickedState = atom({
  key: "aiAssistantClickedState",
  default: false,
});

export function useAiAssistantClickedState() {
  return useRecoilState(aiAssistantClickedState);
}

export const gptResponseState = atom({
  key: "gptResponseState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const speedDialState = atom({
  key: "speedDialState",
  default: "",
});

export const displayedTextState = atom({
  key: "displayedTextState",
  default: "",
});

export function useDisplayedTextState() {
  return useRecoilState(displayedTextState);
}

export const uploadingState = atom({
  key: "uploadingState",
  default: false,
});

export function useUploadingState() {
  return useRecoilState(uploadingState);
}

export const notificationState = atom({
  key: "notificationState",
  default: { isVisible: false, message: "" },
});

export const documentIdState = atom({
  key: "documentIdState",
  default: { isVisible: false, message: "" },
});

export const noteStyleState = atom({
  key: "noteStyleState",
  default: "",
});

export function useNoteStyleState() {
  return useRecoilState(noteStyleState);
}
