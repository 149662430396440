import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { noteStyleState } from "../../Global/GlobalStates";
import { useRecoilState } from "recoil";
import { Divider } from "@mui/material";

export default function DropdownMenu() {
  const [style, setStyle] = useRecoilState(noteStyleState);

  const handleChange = (event) => {
    setStyle(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <InputLabel id="select-small-label">Note Style</InputLabel>
      <Select
        labelId="select-small-label"
        id="select-small"
        value={style}
        label="Note Style"
        onChange={handleChange}
      >
        <MenuItem disabled>Medical Practitioner</MenuItem>
        <Divider />
        <MenuItem value="Chief Complaint">Chief Complaint</MenuItem>
        <MenuItem value="SOAP">SOAP</MenuItem>
        <Divider />
        <MenuItem disabled>Allied Health Provider</MenuItem>
        <Divider />
        <MenuItem value="Psychologist">Psychologist</MenuItem>
      </Select>
    </FormControl>
  );
}
