// RecordButton.js
import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { fetchDeepgramToken, fetchGPTResponse } from "../utils/apiCalls"; // Assuming these are in ../utils/
import { startRecording, stopRecording } from "../utils/audioRecording"; // Assuming these are in ../utils/
import { formatTime } from "../utils/utilities"; // Assuming this is in ../utils/
import BasicButton from "./BasicButton";
import MicNoneIcon from "@mui/icons-material/MicNone";
import LoadingButton1 from "./LoadingButton";
import RecordingButton from "./RecordingButton";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import { notificationState } from "../Global/GlobalStates";
import {
  gptResponseState,
  uploadingState,
  useNoteStyleState,
} from "../Global/GlobalStates";

export default function RecordButton({ onGptResponse, onTranscript }) {
  const mediaRecorder = useRef(null);
  const audioBlob = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deepgramToken, setDeepgramToken] = useState("");
  const setNotification = useSetRecoilState(notificationState);
  const [timer, setTimer] = useState(0);
  const [gptResponses, setGptResponses] = useRecoilState(gptResponseState);
  const uploading = useRecoilValue(uploadingState);
  const noteStyle = useNoteStyleState()[0];

  const startTime = useRef();
  const intervalRef = useRef();
  const serverUrl = "https://server.calvin-ai.com";

  useEffect(() => {
    console.log("GPT Responses logged in RecordButton:", gptResponses);
  }, [gptResponses]);

  useEffect(() => {
    console.time("Fetch Deepgram Token");
    fetchDeepgramToken(serverUrl)
      .then((token) => {
        setDeepgramToken(token);
        console.timeEnd("Fetch Deepgram Token");
      })
      .catch((error) => {
        console.timeEnd("Fetch Deepgram Token");
      });
  }, []);

  useEffect(() => {
    if (isRecording) {
      startTime.current = Date.now();
      intervalRef.current = setInterval(() => {
        setTimer(Date.now() - startTime.current);
      }, 1);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isRecording]);

  const handleButtonClick = async () => {
    console.time("Button Click Handler");
    if (isRecording) {
      stopRecording(
        mediaRecorder,
        audioBlob,
        setIsRecording,
        setIsLoading,
        deepgramToken,
        (transcript) => {
          fetchGPTResponse(serverUrl, transcript, noteStyle)
            .then((gptResponse) => {
              const timestamp = new Date();
              const day = String(timestamp.getDate()).padStart(2, "0");
              const month = String(timestamp.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
              const year = timestamp.getFullYear();
              const hour = String(timestamp.getHours() % 12 || 12).padStart(
                2,
                "0"
              ); // Convert 24hr to 12hr format
              const minute = String(timestamp.getMinutes()).padStart(2, "0");
              const second = String(timestamp.getSeconds()).padStart(2, "0");
              const ampm = timestamp.getHours() >= 12 ? "PM" : "AM";

              const local12hrTime = `${day}/${month}/${year}, ${hour}:${minute}:${second} ${ampm}`;

              setGptResponses((prevResponses) => ({
                ...prevResponses,
                [local12hrTime]: gptResponse,
              }));
              onGptResponse(gptResponse);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log("Error fetching GPT response:", error);
              setIsLoading(false);
            });
        },
        onTranscript,
        setNotification
      );
    } else {
      startRecording(setNotification, setIsRecording, mediaRecorder, audioBlob);
    }
    console.timeEnd("Button Click Handler");
  };

  return (
    <div>
      {isLoading || uploading ? (
        <LoadingButton1
          sx={{ paddingTop: "6px", paddingBottom: "5px" }}
          // Add any additional props for the LoadingButton
        />
      ) : isRecording ? (
        <RecordingButton
          sx={{ paddingTop: "6px", paddingBottom: "5px" }}
          text={`Stop Rec | ${formatTime(timer)}`}
          bgColor="#FF0000" // Red color for recording
          textColor="#FFFFFF"
          startIcon={<MicNoneIcon />}
          onClick={handleButtonClick}
        />
      ) : (
        <BasicButton
          sx={{ paddingTop: "6px", paddingBottom: "5px" }}
          text="Start Recording"
          startIcon={<MicNoneIcon />}
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
}
