import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationState } from "../../Global/GlobalStates";
import "./NotificationTab.css";
import Button from "@mui/material/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

const NotificationTab = () => {
  const [{ isVisible, message }, setNotification] =
    useRecoilState(notificationState);

  useEffect(() => {
    let ws;

    // Function to initialize WebSocket connection
    const connect = () => {
      ws = new WebSocket("wss://droplet.calvin-ai.com/ws"); // Adjust with your WebSocket endpoint

      ws.onopen = () => {
        // console.log("Connected to WebSocket");
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.updateAvailable) {
          setNotification({
            isVisible: true,
            message: data.message,
          });
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error", error);
      };

      ws.onclose = () => {
        // console.log("WebSocket connection closed. Attempting to reconnect...");
        setTimeout(connect, 3000); // Reconnect after 3 seconds
      };
    };

    connect();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [setNotification]);

  const handleClose = () => {
    setNotification({ isVisible: false, message: "" });
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return isVisible ? (
    <div className={`notification-tab ${isVisible ? "slideUp" : "slideDown"}`}>
      {message}
      <Button
        className="refreshButton"
        onClick={message.includes("update") ? handleRefresh : handleClose}
        variant="outlined"
        size="small"
        disableElevation
        sx={{ marginLeft: "10px", padding: "0px", borderRadius: "3px" }}
      >
        {message.includes("update") ? (
          <RefreshIcon fontSize="small" />
        ) : (
          <CloseOutlinedIcon fontSize="small" />
        )}
      </Button>
    </div>
  ) : null;
};

export default NotificationTab;
