// components/FreeTrialIcon/FreeTrialIcon.js
import React, { useState, useEffect } from "react";
import { checkFreeTrialStatus } from "../../utils/checkFreeTrialStatus";
import "./FreeTrialIcon.css";

const FreeTrialIcon = ({ email }) => {
  const [daysLeft, setDaysLeft] = useState(null);
  // console.log("Email from FreeTrialIcon:", email.email);

  useEffect(() => {
    const fetchFreeTrialStatus = async () => {
      // console.log("Logging FreeTrialIcon renders");
      const days = await checkFreeTrialStatus({
        email: email.email,
      });
      // console.log("Days left from fetchFreeTrialStatus in FreeTrialIcon:",days);
      setDaysLeft(days);
    };

    fetchFreeTrialStatus();
  }, [email]);

  if (daysLeft === null) {
    return null;
  }

  if (daysLeft <= 0) {
    return null;
  }

  return <div className="free-trial-icon">Trial: {daysLeft} days left</div>;
};

export default FreeTrialIcon;
