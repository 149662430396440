import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import QuizIcon from "@mui/icons-material/Quiz";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EmailIcon from "@mui/icons-material/Email";
import Logout from "@mui/icons-material/Logout";
import UserIcon from "./UserIcon/UserIcon";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BasicButton from "./BasicButton";
import "../App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { isPayingCustomer } from "../utils/isPayingCustomer";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import createStripeCheckoutSession from "../utils/createStripeCheckoutSession";

export default function AccountMenu() {
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPayingCustomerStatus, setIsPayingCustomer] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchPayingCustomerStatus = async () => {
      // console.log(
      //  "User email from fetchPayingCustomerStatus function in AccountMenu:",
      //   user.email
      // );
      const status = await isPayingCustomer({ email: user.email });
      setIsPayingCustomer(status);
      const stripeCheckoutUrl = await createStripeCheckoutSession({
        email: user.email,
      });
      setCheckoutUrl(stripeCheckoutUrl);
    };

    if (user && user.email) {
      fetchPayingCustomerStatus();
    }
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openStripeCustomerPortal = () => {
    window.open(
      "https://billing.stripe.com/p/login/6oEdSPexf8bk3ludQQ",
      "_blank"
    );
  };

  const openStripeLink = () => {
    window.open(checkoutUrl);
  };

  const handleSendEmail = () => {
    const mailtoLink = `mailto:team@calvin-ai.com?subject=Calvin AI&body=Dear Calvin AI Team,`;
    window.location.href = mailtoLink;
  };

  const openFAQPage = () => {
    window.open("https://www.calvin-ai.com/#FAQPage", "_blank");
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account Settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <UserIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isPayingCustomerStatus ? (
          <MenuItem onClick={openStripeCustomerPortal}>
            <BasicButton
              className="upgradeButton"
              startIcon={<ManageAccountsIcon />}
              text="Manage Account"
            />
          </MenuItem>
        ) : (
          <MenuItem onClick={openStripeLink}>
            <BasicButton
              className="upgradeButton"
              startIcon={<AutoAwesomeIcon />}
              text="Upgrade to Pro"
            />
          </MenuItem>
        )}
        <Divider />
        <MenuItem sx={{ fontSize: "15px" }} onClick={openFAQPage}>
          <ListItemIcon>
            <QuizIcon fontSize="small" />
          </ListItemIcon>
          FAQ Page
        </MenuItem>
        <MenuItem sx={{ fontSize: "15px" }} onClick={handleSendEmail}>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          Contact
        </MenuItem>

        <MenuItem
          sx={{ fontSize: "15px" }}
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
