import React, { useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import { isPayingCustomer } from "./utils/isPayingCustomer";
import { createRoot } from "react-dom/client";
import TrialExpiry from "./components/TrialExpiryPage/TrialExpiryPage";
import { checkFreeTrialStatus } from "./utils/checkFreeTrialStatus";
import App from "./App";
import "./App.css";
import "./index.css";
import { RecoilRoot } from "recoil";

const AuthenticatedApp = () => {
  const {
    isLoading: authLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
  } = useAuth0();
  const [isCustomer, setIsCustomer] = useState(false);
  const [hasFreeTrial, setHasFreeTrial] = useState(false);
  const [statusCheckLoading, setStatusCheckLoading] = useState(true); // New state for status check

  useEffect(() => {
    if (isAuthenticated && user && user.email) {
      Promise.all([
        isPayingCustomer({ email: user.email }),
        checkFreeTrialStatus({ email: user.email }),
        // console.log("User email in index.js:", user.email),
      ])
        .then(([customerStatus, trialStatus]) => {
          setIsCustomer(customerStatus);
          setHasFreeTrial(trialStatus);
          // console.log(
          //  "Free Trial Status from checkFreeTrialStatus in index.js:",
          //  trialStatus
          // );
          setStatusCheckLoading(false); // Set loading to false after checks are complete
        })
        .catch((error) => {
          console.error("Error:", error);
          setStatusCheckLoading(false);
        });
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (!authLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [authLoading, isAuthenticated, loginWithRedirect]);

  if (authLoading || statusCheckLoading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!isCustomer && !hasFreeTrial) {
    return <TrialExpiry />;
  }

  return (
    <RecoilRoot>
      <App />
    </RecoilRoot>
  );
};

const root = createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    domain="dev-v7a5ioospfgu6dg4.us.auth0.com"
    clientId="anaLA7DDsjnMdrZgX1kv4rBfVObhCe4I"
    authorizationParams={{ redirect_uri: window.location.origin }}
  >
    <AuthenticatedApp />
  </Auth0Provider>
);
