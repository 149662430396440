import React from "react";
import { Button } from "@mui/material";
import "./Header.css";

const Header = () => {
  const handleSendEmail = () => {
    const mailtoLink = `mailto:team@calvin-ai.com?subject=Calvin AI&body=Dear Calvin AI Team,`;
    window.location.href = mailtoLink;
  };
  return (
    <header className="header">
      <div className="header-left">
        <a
          href="http://www.calvin-ai.com"
          className="logo-link"
          target="_blank"
          rel="noreferrer"
        >
          <h4 className="logo-top-left">Calvin AI</h4>
        </a>
      </div>
      <div className="header-right">
        <Button
          variant="outlined"
          disableElevation
          sx={{ color: "#0067ff" }}
          onClick={handleSendEmail}
        >
          Contact
        </Button>
      </div>
    </header>
  );
};

export default Header;
