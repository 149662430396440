import "./TrialExpiryPage.css";
import TitlePage from "./components/TitlePage/TitlePage";
import Header from "./components/Header/Header";

function TrialExpiry() {
  return (
    <div className="main-container">
      <Header />
      <div className="main-content">
        <TitlePage />
      </div>
    </div>
  );
}

export default TrialExpiry;
