const copyContentToClipboard = () => {
  const editor = window.tinymce.activeEditor; // Get the active TinyMCE editor
  if (editor) {
    const content = editor.getContent(); // Get the HTML content from the editor
    const plainText = editor.getContent({ format: "text" }); // Get the plain text content

    // Create blobs for both HTML and plain text content
    const htmlBlob = new Blob([content], { type: "text/html" });
    const textBlob = new Blob([plainText], { type: "text/plain" });

    // Create a ClipboardItem with both blobs
    const clipboardItem = new ClipboardItem({
      "text/html": htmlBlob,
      "text/plain": textBlob,
    });

    // Use the Clipboard API to copy the content
    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        console.log(
          "Content copied to clipboard in both HTML and plain text formats"
        );
      })
      .catch((err) => {
        console.error("Failed to copy content: ", err);
      });
  }
};

export default copyContentToClipboard;
