import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import "./App.css";
import TinyMCE from "./components/TinyMCE";
import RecordButton from "./components/RecordButton";
import BasicButton from "./components/BasicButton";
import DropdownMenu from "./components/DropdownMenu/DropdownMenu.jsx";
import AccountMenu from "./components/AccountMenu";
import copyContentToClipboard from "./utils/copyToClipboard";
import FreeTrialIcon from "./components/FreeTrialIcon/FreeTrialIcon";
import BasicSpeedDial from "./components/BasicSpeedDial.jsx";
import NotificationTab from "./components/NotificationTab/NotificationTab.jsx";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  aiAssistantClickedState,
  displayedTextState,
} from "./Global/GlobalStates.jsx";
import { useAuth0 } from "@auth0/auth0-react";
import { gptResponseState } from "./Global/GlobalStates.jsx";
import HistoryTab from "./components/HistoryTab/HistoryTab.jsx";
import FileUploader from "./components/FileUpload/FileUploader.jsx";

export default function App() {
  const { user } = useAuth0();
  const textTimeoutRef = useRef(null);
  const [transcript, setTranscript] = useState("");
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [gptText, setGptText] = useState("");
  const isResponseProcessedRef = useRef(false);
  const [gptResponses] = useRecoilState(gptResponseState);
  const setAiAssistantClicked = useSetRecoilState(aiAssistantClickedState);
  const [displayedText, setDisplayedText] = useRecoilState(displayedTextState);

  const handleUploadComplete = () => {
    // Set aiAssistantClicked to false when the upload is complete
    setAiAssistantClicked(false);
  };

  const startTextStreaming = useCallback(() => {
    const length = gptText.length;

    const updateText = (index) => {
      if (!isPageVisible) {
        // If the page is not visible, stop streaming
        return;
      }

      if (index < length) {
        setDisplayedText((prev) => prev + gptText.charAt(index));
        textTimeoutRef.current = setTimeout(() => updateText(index + 1), 4);
      }
    };

    if (length > 0) {
      textTimeoutRef.current = setTimeout(() => updateText(0), 4);
    }
  }, [gptText, isPageVisible, setDisplayedText]);

  const displayText = useCallback(() => {
    // Clear any ongoing streaming
    if (textTimeoutRef.current) {
      clearTimeout(textTimeoutRef.current);
    }

    startTextStreaming();
    isResponseProcessedRef.current = true; // Set the flag to true after processing the response
  }, [startTextStreaming]);

  const handleVisibilityChange = useCallback(() => {
    setIsPageVisible(!document.hidden);

    // If the page becomes visible, trigger the display of text
    if (!document.hidden) {
      displayText();
    }
  }, [displayText]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  useEffect(() => {
    // Clear any ongoing streaming when gptText changes
    if (textTimeoutRef.current) {
      clearTimeout(textTimeoutRef.current);
    }

    // If the page is currently visible and the response hasn't been processed, start streaming the text
    if (isPageVisible && !isResponseProcessedRef.current) {
      startTextStreaming();
      isResponseProcessedRef.current = true; // Set the flag to true after processing the response
    } else {
      // If the page is not currently visible or the response has been processed, display the full text immediately
      setDisplayedText(gptText);
    }
  }, [gptText, isPageVisible, startTextStreaming, setDisplayedText]);

  const handleGptResponse = (response) => {
    setGptText(response);
    setDisplayedText("");
    isResponseProcessedRef.current = false; // Reset the flag when a new response is received

    // If the page is currently visible, start streaming the text
    if (isPageVisible) {
      startTextStreaming();
    } else {
      // If the page is not currently visible, display the full text immediately
      setDisplayedText(response);
    }
  };

  // Function to handle transcript received from RecordButton
  const handleTranscript = (receivedTranscript) => {
    setTranscript(receivedTranscript);
    // console.log("Transcript from App.js:", receivedTranscript);
  };

  const handleListItemClick = (key) => {
    console.log("handleListItemClick Key:", key);
    console.log("GPT Responses logged in App:", gptResponses);
    const gptResponse = gptResponses[key];
    console.log("handleListItemClick Response:", gptResponse);
    if (gptResponse) {
      setDisplayedText(gptResponse);
    }
  };

  const emailProps = useMemo(() => ({ email: user.email }), [user.email]);

  return (
    <div className="App">
      <div className="notification-tab-container">
        <NotificationTab />
      </div>
      <HistoryTab onListItemClick={handleListItemClick} />
      <div className="top-container">
        <div className="left-container">
          <RecordButton
            onGptResponse={handleGptResponse}
            onTranscript={handleTranscript}
          ></RecordButton>
          <BasicButton
            className="copy-button"
            bgColor="#f6f8fb"
            text="Copy Text"
            type="outlined"
            textColor="#5f5f5f"
            outlineColor="1px solid #dedfe1"
            onClick={copyContentToClipboard}
            startIcon={<ContentCopyIcon />}
          ></BasicButton>
        </div>

        <h1 className="header-title">
          <a
            href="http://www.calvin-ai.com"
            className="logo-link"
            target="_blank"
            rel="noreferrer"
          >
            Calvin AI
          </a>
        </h1>

        <div className="right-container">
          <FreeTrialIcon email={emailProps} />
          <DropdownMenu />
          <AccountMenu />
        </div>
      </div>
      <div className="centered-container">
        <div className="RichTextEditorContainer">
          <TinyMCE
            text={displayedText}
            gptTextResponse={gptText}
            audioTranscript={transcript}
          ></TinyMCE>
        </div>
      </div>
      <div className="file-uploader-container">
        <FileUploader onUploadComplete={handleUploadComplete} />
      </div>
      <BasicSpeedDial />
    </div>
  );
}
