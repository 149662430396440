// audioRecording.js
import { generateFilename } from "./utilities";

export const startRecording = async (
  setNotification,
  setIsRecording,
  mediaRecorder,
  audioBlob
) => {
  console.time("Start Recording");
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder.current = new MediaRecorder(stream);

    mediaRecorder.current.ondataavailable = (event) => {
      audioBlob.current = event.data;
    };

    mediaRecorder.current.start();
    setIsRecording(true);
    console.timeEnd("Start Recording");
  } catch (error) {
    console.timeEnd("Start Recording");
    setNotification({
      isVisible: true,
      message: `Microphone error: ${error.message}. Please check your settings and try again.`,
    });
    console.error("Error starting recording:", error);
  }
};

export const stopRecording = async (
  mediaRecorder,
  audioBlob,
  setIsRecording,
  setIsLoading,
  deepgramToken,
  fetchGPTResponse,
  onTranscript,
  setNotification // Assuming you want to notify the user
) => {
  console.time("Stop Recording");
  if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
    mediaRecorder.current.stop();

    mediaRecorder.current.onstop = async () => {
      setIsRecording(false);

      // Check if the audioBlob has data
      if (audioBlob.current && audioBlob.current.size > 0) {
        console.time("Deepgram Audio Processing");
        setIsLoading(true); // Only set loading true if there is data to process

        const filename = generateFilename(audioBlob.current.type);
        const formData = new FormData();
        formData.append("file", audioBlob.current, filename);

        fetch(
          "https://api.deepgram.com/v1/listen?model=whisper&utterances=true&diarize=true&punctuate=true",
          {
            method: "POST",
            headers: {
              Authorization: `Token ${deepgramToken}`,
            },
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            let transcript = "";
            if (data && data.results && data.results.utterances) {
              data.results.utterances.forEach((utterance, index) => {
                transcript += index !== 0 ? "<br>" : "";
                transcript += `Speaker ${utterance.speaker}: ${utterance.transcript}`;
              });
            } else {
              console.error("Unexpected response structure:", data);
            }
            console.timeEnd("Deepgram Audio Processing");
            fetchGPTResponse(transcript);
            if (onTranscript) {
              onTranscript(transcript);
            }
          })
          .catch((error) => {
            console.error("Error posting to Deepgram:", error);
            setIsLoading(false);
            setNotification({
              isVisible: true,
              message: "Error transcribing audio. Please try again.",
            });
          });
      } else {
        setIsLoading(false);
        setNotification({
          isVisible: true,
          message: "No audio was recorded. Please try again.",
        });
      }
    };
  }
  console.timeEnd("Stop Recording");
};
