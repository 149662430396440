// utils/checkFreeTrialStatus.js
import Airtable from "airtable";

export const checkFreeTrialStatus = async ({ email }) => {
  const trialPeriod = 7; // Total trial period in days
  const base = new Airtable({
    apiKey:
      "patExxfxzWpvWohlG.434540fe6cbde170a1cee85613b3b8c269dfae856940b31b4b7b1002563c26f1",
  }).base("appkNDpSEoya1GDqK");

  try {
    const records = await base("User Details")
      .select({
        view: "Grid view",
        fields: ["Email", "SignUpDate"],
      })
      .firstPage();

    const userRecord = records.find((record) => record.fields.Email === email);
    if (userRecord) {
      const signUpDate = userRecord.fields.SignUpDate;
      // console.log(`Free Trial Days Left on Airtable: ${calculateDaysLeft(signUpDate, trialPeriod)}`);
      return calculateDaysLeft(signUpDate, trialPeriod);
    }
    return null;
  } catch (err) {
    console.error("Error fetching from Airtable:", err);
    return null;
  }
};

const calculateDaysLeft = (signUpDate, trialPeriod) => {
  const signUpDateTime = new Date(signUpDate).getTime();
  const currentDateTime = new Date().getTime();
  const diffTime = Math.abs(currentDateTime - signUpDateTime);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return Math.max(trialPeriod - diffDays, 0);
};
