import * as React from "react";
import Button from "@mui/material/Button";
import "../App.css";

export default function RecordingButton({
  text,
  bgColor,
  textColor,
  startIcon,
  ...props
}) {
  return (
    <Button
      variant="contained"
      startIcon={startIcon}
      style={{
        backgroundColor: bgColor || "#0067FF", // This will use the color prop if provided, otherwise default to "#0067FF"
        color: textColor, // Apply text color
        borderRadius: "5px",
      }}
      {...props}
    >
      {text}
    </Button>
  );
}

// Set default props
RecordingButton.defaultProps = {
  text: "Default Text",
  bgColor: "#0067FF",
  textColor: "#FFFFFF", // Default text color is white
  startIcon: null, // No icon by default
};
