import axios from "axios"; // You may need to install axios for HTTP requests

const createStripeCheckoutSession = async ({ email }) => {
  const serverUrl = "https://server.calvin-ai.com";
  try {
    // Define the request payload
    // console.log("User email in createStripeCheckoutSession:", email);
    const payload = {
      customer_email: email, // Prefill the customer's email
      line_items: [{ price: "price_1OD0dkHDPnb5dRVPyYLyMxxg", quantity: 1 }],
      currency: "aud",
      mode: "subscription", // Assuming this is for one-time payments
      success_url: "https://app.calvin-ai.com/",
      cancel_url: "https://app.calvin-ai.com/",
    };

    // Call your backend to create a Stripe session
    const response = await axios.post(
      `${serverUrl}/create-checkout-session`,
      payload
    );

    // Return the session URL
    // console.log("Stripe Checkout URL:", response.data.url);
    return response.data.url;
  } catch (error) {
    console.error("Error creating Stripe checkout session:", error);
    throw error;
  }
};

export default createStripeCheckoutSession;
