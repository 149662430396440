import React, { useEffect, useState } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import {
  aiAssistantClickedState,
  savedNotesClickedState,
} from "../Global/GlobalStates";
import { speedDialState } from "../Global/GlobalStates";
import { useRecoilState, useSetRecoilState } from "recoil";

const actions = [
  { icon: <DeleteIcon />, name: "Clear" },
  { icon: <BookmarksIcon fontSize="small" />, name: "Saved Notes" },
  { icon: <AutoFixHighIcon />, name: "Document AI" },
];

export default function BasicSpeedDial() {
  const [selectedOption, setSelectedOption] = useRecoilState(speedDialState);
  const setSavedNotesClicked = useSetRecoilState(savedNotesClickedState);
  const setAiAssistantClickedState = useSetRecoilState(aiAssistantClickedState);
  const [dialDirection, setDialDirection] = useState("up");

  const updateDialDirection = () => {
    if (window.innerWidth <= 1000) {
      setDialDirection("left");
    } else {
      setDialDirection("up");
    }
  };

  useEffect(() => {
    updateDialDirection();
    window.addEventListener("resize", updateDialDirection);

    return () => {
      window.removeEventListener("resize", updateDialDirection);
    };
  }, []);

  useEffect(() => {
    console.log("FAB Selected Option:", selectedOption);
  }, [selectedOption]);

  const handleActionClick = (actionName) => {
    setSelectedOption(actionName);
    if (actionName === "Saved Notes") {
      setSavedNotesClicked((prev) => !prev);
    } else if (actionName === "Document AI") {
      setAiAssistantClickedState((prev) => !prev);
    }
    // Additional logic for when an action is selected can go here
  };

  return (
    <SpeedDial
      direction={dialDirection}
      ariaLabel="SpeedDial basic"
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        "& .MuiSpeedDial-fab": {
          bgcolor: "rgb(0, 103, 255)",
          "&:hover": {
            bgcolor: "rgb(0, 80, 200)",
          },
          "&:focus": {
            bgcolor: "rgb(0, 80, 200)",
          },
        },
      }}
      icon={<SpeedDialIcon />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleActionClick(action.name)}
        />
      ))}
    </SpeedDial>
  );
}
