import axios from "axios";

// apiCalls.js
export const fetchDeepgramToken = async (serverUrl) => {
  console.time("Fetching Deepgram Token");
  try {
    const response = await fetch(`${serverUrl}/deepgram-config`);
    const data = await response.json();
    console.timeEnd("Fetching Deepgram Token");
    return data.deepgramConfig.www;
  } catch (error) {
    console.timeEnd("Fetching Deepgram Token");
    console.error("Error fetching API keys:", error);
    throw error;
  }
};

export const fetchGPTResponse = async (serverUrl, transcript, noteStyle) => {
  console.time("Fetching GPT Response");
  try {
    const response = await axios.post(`${serverUrl}/gpt`, {
      transcript,
      noteStyle,
    });
    console.timeEnd("Fetching GPT Response");
    return response.data.choices[0].message.content;
  } catch (error) {
    console.timeEnd("Fetching GPT Response");
    console.error("Error:", error);
    throw error;
  }
};
