import * as React from "react";
import Button from "@mui/material/Button";
import "../App.css";

export default function BasicButton({
  text,
  bgColor,
  textColor,
  startIcon,
  type,
  outlineColor,
  ...props
}) {
  return (
    <Button
      variant={type}
      startIcon={startIcon}
      style={{
        backgroundColor: bgColor || "#0067FF", // This will use the color prop if provided, otherwise default to "#0067FF"
        color: textColor, // Apply text color
        borderRadius: "5px",
        border: outlineColor,
      }}
      {...props}
    >
      {text}
    </Button>
  );
}

// Set default props
BasicButton.defaultProps = {
  text: "Default Text",
  bgColor: "#0067FF",
  textColor: "#FFFFFF", // Default text color is white
  startIcon: null, // No icon by default
  type: "contained",
  outlineColor: "",
};
