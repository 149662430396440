import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import "./UserIcon.css"; // Assuming you'll have some custom styles

export default function UserIcon({ handleClick }) {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="user-icon">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <div className="user-icon" onClick={handleClick}>
      {isAuthenticated ? (
        <img src={user.picture} alt={user.name} />
      ) : (
        <PersonOutlineOutlinedIcon />
      )}
    </div>
  );
}
