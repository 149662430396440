import * as React from "react";
import { useEffect, useState } from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItemButton from "@mui/joy/ListItemButton";
import Sheet from "@mui/joy/Sheet";
import { useSavedNotesClicked } from "../../Global/GlobalStates";
import IconButton from "@mui/joy/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useRecoilState } from "recoil";
import { gptResponseState } from "../../Global/GlobalStates";
import "./HistoryTab.css";

export default function HistoryTab({ onListItemClick }) {
  const [gptResponses, setGptResponses] = useRecoilState(gptResponseState);
  const [savedNotesClicked] = useSavedNotesClicked(); // Only retrieve the state, not the setter
  const [isVisible, setIsVisible] = useState(false);

  const handleDelete = (key) => {
    setGptResponses((prevResponses) => {
      const newResponses = { ...prevResponses };
      delete newResponses[key];
      return newResponses;
    });
  };

  useEffect(() => {
    if (savedNotesClicked) {
      setIsVisible(true);
    } else {
      const timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 500); // 500ms matches your animation duration
      return () => clearTimeout(timeoutId);
    }
  }, [savedNotesClicked]);

  return isVisible ? (
    <div
      className={`HistoryTab ${savedNotesClicked ? "slide-in" : "slide-out"}`}
      style={{
        position: "absolute",
        marginTop: "153.5px",
        left: 0,
        zIndex: 1000,
        maxWidth: "20%",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          backgroundColor: "white",
          width: "100%",
          maxHeight: "80vh",
          overflow: "auto",
          border: "none",
          borderRadius: "0px 5px 5px 0px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <List>
          <ListSubheader
            sticky
            sx={{
              textTransform: "none",
              fontSize: "14px",
              color: "black",
              fontWeight: "normal",
              fontFamily: "sans-serif",
            }}
          >
            Notes Are Only Saved Per Session & Are Permanently Deleted After
            Every Browser Refresh.
          </ListSubheader>
          <List>
            {Object.keys(gptResponses).map((key, index) => (
              <ListItem
                key={index}
                endAction={
                  <div>
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      sx={{ color: "#bdbdbd" }}
                      onClick={() => handleDelete(key)}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </div>
                }
              >
                <ListItemButton
                  sx={{ fontFamily: "sans-serif" }}
                  onClick={() => onListItemClick(key)}
                >
                  {key}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </List>
      </Sheet>
    </div>
  ) : null;
}
